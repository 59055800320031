/* eslint-disable jsx-a11y/media-has-caption */
import { faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";

type Props = Readonly<{
  video: string;
  videoWidth: number;
  videoHeight: number;
}>;

export function ModalVideo({
  video,
  videoWidth,
  videoHeight,
}: Props): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <div className="flex justify-center">
      <button
        className="btn primary no-underline gap-2"
        onClick={() => {
          setModalOpen(true);
        }}
        aria-label="Watch the video"
      >
        Watch video
        <FontAwesomeIcon className="h-4" icon={faPlay} />
      </button>
      <Transition
        show={modalOpen}
        as={Fragment}
        afterEnter={() => videoRef.current?.play()}
      >
        <Dialog initialFocus={videoRef} onClose={() => setModalOpen(false)}>
          <Transition.Child
            className="fixed inset-0 z-[99999] bg-black bg-opacity-50 transition-opacity"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
            as="div"
          />
          <Transition.Child
            className="fixed inset-0 z-[99999] flex px-4 md:px-6 py-6"
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <div className="max-w-5xl mx-auto h-full flex items-center">
              <Dialog.Panel className="w-full max-h-full rounded-3xl shadow-2xl bg-black overflow-hidden">
                <video
                  src={video}
                  width={videoWidth}
                  height={videoHeight}
                  loop
                  controls
                  autoPlay
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
}
